:where(.css-dev-only-do-not-override-1b0bdye).ant-steps.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description{
    max-width: 100%;
}

:where(.css-dev-only-do-not-override-1b0bdye).ant-steps .ant-steps-item-process .ant-steps-item-icon {
    background-color: #0c3a51;
    border-color: #0c3a51;
}

:where(.css-dev-only-do-not-override-1b0bdye).ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title{
    font-weight: bold;
}
.modalClose {
    float: right !important;
    margin-top: -12px;
    cursor: pointer;
}

.ant-notification-notice{
    z-index: 3000 !important;
}