.login-container {
  font-family: "Jost", sans-serif;
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
  align-items: center;
  justify-content: center;
  height: 100vh;
}
h2 {
  font-family: "Jost", sans-serif;
  color: var(--grey-color);
}
.login-form {
  width: 350px;
}
.input-field {
  margin-bottom: 7px;
}

.input-with-icon {
  display: flex;
  align-items: center;
}

.input-with-icon .input-icon {
  margin-right: 10px;
  margin-bottom: 10px;
}

.input-field {
  flex: 1;
}

.error {
  border-radius: 4px;
}
.message-container {
  width: 350px;
  margin-left: 7px;
}
