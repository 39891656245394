.home-container {
  font-family: "Jost", sans-serif;
  display: flex;
  background-color: var(--background-color);
  align-items: center;
  justify-content: center;
  height: 90vh;
}

.centered-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bigger-card {
  width: 300px;
  height: 200px;
}

.icon-container {
  margin-bottom: 20px;
}

.input-field {
  width: 250px;
}
