.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  background-color: var(--secondary-color);
}

.logo-container {
  flex: 1;
}
.user-functions {
  display: flex;
  align-items: center;
  font-family: "Jost", sans-serif;
}

.logo-image {
  height: 80px;
  width: auto;
  margin-left: 100px;
}

.user-info-container {
  font-family: "Jost", sans-serif;
  display: flex;
  align-items: center;
}

.user-email {
  margin-right: 10px;
}

.avatar {
  width: 40px;
  height: 40px;
  background-color: var(--accent-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: white;
}
