body {
  margin: 0;
  font-family: "Jost", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  /* Define your color variables */
  --background-color: #f9f9f9;
  --primary-color: #00bfb3;
  --secondary-color: #f6f6f6;
  --accent-color: #f09d51;
  --grey-color: #666564;

  /* Define your font variables */
  --main-font: "Jost", sans-serif;
}
